import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import Container from '../components/Container'
import SEO from '../components/SEO'
import Disqus from '../components/Disqus'
import Adsense from '../components/Adsense'
import AsciiTableChar from '../components/AsciiTable/AsciiTableChar'
import './page.scss'

class AsciiPageTemplate extends React.Component {
  render() {
    const pageContext = this.props.pageContext
    const data = pageContext.data
    const pageTitle = `Ascii value of ${data['char']}`
    const pageDescription = `Ascii value of ${data['description']} is ${data['decimal']}. Find complete Ascii Table, Ascii Codes, Ascii Chart, Ascii Characters, Ascii symbols with decimal, binary, octal, and hex conversions on AsciiTable.xyz.`

    return (
      <Layout
        location={this.props.location}
        siteMeta={this.props.data.site.siteMetadata}
      >
        <SEO
          title={pageTitle}
          description={pageDescription}
          image={null}
          pathname={pageContext.slug}
          article={true}
        />
        <main className="page-main-content">
          <Container>
            <div className="single-content">
              <article
                itemScope
                itemType="https://schema.org/Article"
                className="page-content"
              >
                <header className="page-header">
                  <div className="page-header-text">
                    <h1 className="page-header-title" itemProp="headline">
                      {pageTitle}
                    </h1>
                  </div>
                </header>
                <section className="page-body" itemProp="articleBody">
                  <blockquote className="info">
                    <p>
                      Ascii Value of {data['description']} is &nbsp;
                      <strong style={{ fontSize: '1.4rem' }}>
                        {data['decimal']}
                      </strong>
                    </p>
                  </blockquote>
                  <AsciiTableChar
                    start={data['decimal']}
                    end={data['decimal']}
                  />
                  <p>
                    Refer to the full <a href="/">Ascii Table →</a>
                  </p>
                </section>
              </article>
              <Adsense type="article-bottom-responsive" />
              <Disqus
                location={this.props.location}
                disqusShortname={
                  this.props.data.site.siteMetadata.disqusShortname
                }
              />
            </div>
          </Container>
        </main>
      </Layout>
    )
  }
}

export default AsciiPageTemplate

export const pageQuery = graphql`
  query AsciiPageBySlug {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        disqusShortname
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "post" } } }
    ) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
            description
            featured_image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
