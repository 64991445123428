import React from 'react'
import { getAsciiTable } from '../../util'
import PropTypes from 'prop-types'
import './AsciiTable.scss'

class AsciiTableChar extends React.Component {
  render() {
    const asciiTable = getAsciiTable(+this.props.start, +this.props.end)

    return (
      <div className="ascii-table">
        <table>
          <thead>
            <tr>
              <th>Char</th>
              <th>Dec</th>
              <th>Hex</th>
              <th>Octal</th>
              <th>Binary</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {asciiTable.map((data) => (
              <tr key={data['decimal']}>
                <td className="value">
                  <span>{data['char']}</span>
                </td>
                <td className="key">
                  <span>{data['decimal']}</span>
                </td>
                <td>{data['hex']}</td>
                <td>{data['octal']}</td>
                <td>{data['binary']}</td>
                <td>{data['description']}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default AsciiTableChar

AsciiTableChar.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
}

AsciiTableChar.defaultProps = {
  start: 0,
  end: 127,
}
